import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '@/store/slices';
import { useMixpanelService } from '@/store/services/mixpanel.service';
import { MIXPANEL_TOKEN } from '@/utils/constants';
import { getDefaultAccount, getMixpanelTaskProps, isSuperAdmin } from '@/utils/helpers';
import { TaskType } from '@/utils/types';

const useMixpanel = () => {
	const { data: user } = useSelector((state: RootState) => state.me);
	const { logMixpanelEvent } = useMixpanelService();

	const initializeMixpanel = (email?: string) => {
		if (!MIXPANEL_TOKEN) return;
		mixpanel.init(MIXPANEL_TOKEN, {
			track_pageview: 'url-with-path',
			persistence: 'localStorage',
			ip: true,
		});
		// If a TC User logged as another user then use impersonator email
		// If empty will use default mixpanel distinct_id
		mixpanel.identify(user?.impersonator?.email || user?.email || email);
	};

	const track = (eventName: string, properties?: Record<any, any>) => {
		if (!MIXPANEL_TOKEN || !(mixpanel as any).__loaded) return;
		let workspaceProps = {};

		if (user) {
			if (isSuperAdmin(user)) {
				workspaceProps = {
					user_workspace: 'Universe',
					user_group: 'Super Admin',
				};
			} else {
				const defaultAccount = getDefaultAccount(user)!;
				workspaceProps = {
					user_workspace: defaultAccount.organization.name,
					user_group: defaultAccount.group.name,
				};
			}
		}

		const eventProperties = {
			distinct_id: user?.impersonator?.email || user?.email, // if empty will use default mixpanel distinct_id
			...properties,
			...workspaceProps,
			...(user?.impersonator && {
				impersonated_user_email: user?.email,
				impersonated_user_name: user?.userName,
			}),
			$insert_id: uuidv4(),
			current_page_title: document.title,
			current_url_path: location.pathname,
			current_domain: location.hostname,
			current_url_protocol: location.protocol,
			current_url_search: location.search,
			time: Date.now(),
		};

		mixpanel.track(eventName, eventProperties, () => {
			logMixpanelEvent({
				eventName,
				insertId: eventProperties.$insert_id,
			});
		});
	};

	const getMixpanelId = () => {
		if (!MIXPANEL_TOKEN || !(mixpanel as any).__loaded) return;
		return mixpanel.get_distinct_id();
	};

	const resetMixpanel = () => {
		if (!MIXPANEL_TOKEN) return;
		mixpanel.reset();
	};

	const trackTaskUpdates = (task: TaskType, updatedTask: TaskType) => {
		const newTask = { ...task, ...updatedTask };
		if (newTask.status !== task.status) {
			track('Task Status Changed', {
				...getMixpanelTaskProps(newTask),
				status: newTask.status,
				previous_status: task.status,
			});
		}

		if (newTask.workflowStatus !== task.workflowStatus) {
			track('Task Workflow Changed', {
				...getMixpanelTaskProps(newTask),
				workflow_status: newTask.workflowStatus,
				previous_workflow_status: task.workflowStatus,
			});
		}

		if (newTask.summary !== task.summary) {
			track('Task Summary Changed', {
				...getMixpanelTaskProps(newTask),
				task_summary: newTask.summary,
			});
		}

		if (newTask.notes !== task.notes) {
			track('Task Notes Changed', {
				...getMixpanelTaskProps(newTask),
				task_notes: newTask.notes,
			});
		}

		if (newTask.approver?.id !== task.approver?.id) {
			track('Assigned Approver Changed', {
				...getMixpanelTaskProps(newTask),
				approver: newTask.approver?.userName,
			});
		}
	};

	return { initializeMixpanel, track, getMixpanelId, resetMixpanel, trackTaskUpdates };
};

export default useMixpanel;
