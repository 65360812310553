import React, { FC, Fragment, useState } from 'react';
import { slice } from 'lodash';
import { Badge, AvatarGroup as MuiAvatarGroup, Tooltip } from '@mui/material';
import { UserAvatar } from './UserAvatar';
import { UserType } from '@/utils/types';

type AvatarGroupProps = {
	customStyle?: Record<string, any>;
	users: Record<string, any>[];
	maxItems?: number;
	withBadge?: boolean;
};

const styles = {
	avatarContainer: {
		width: '100%',
		justifyContent: 'start',
		'& .MuiAvatar-root': {
			height: '28px',
			width: '28px',
			fontSize: '12px',
		},
	},
	badge: {
		'& .MuiBadge-badge': {
			backgroundColor: '#44b700',
			boxShadow: '0 0 0 1px #FFF',
		},
	},
};

const avatarWithTooltip = (user: Record<string, any>) => (
	<UserAvatar
		customStyle={{ height: 28, width: 28, fontSize: 12 }}
		tooltip
		user={user as UserType}
	/>
);

export const AvatarGroup: FC<AvatarGroupProps> = ({
	users,
	maxItems = 4,
	customStyle = {},
	withBadge = false,
}) => {
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

	const showRemainingUsers = (users: Record<string, any>[], maxItems: number) => {
		return slice(users, maxItems - 1, users.length)
			.map((user) => user.userName)
			.join('\n');
	};

	return (
		<Tooltip
			open={tooltipOpen}
			title={showRemainingUsers(users, maxItems)}
			componentsProps={{ tooltip: { sx: { whiteSpace: 'pre-line' } } }}
		>
			<MuiAvatarGroup
				max={maxItems}
				sx={{ ...styles.avatarContainer, ...customStyle }}
				slotProps={{
					additionalAvatar: {
						onMouseEnter: () => setTooltipOpen(true),
						onMouseLeave: () => setTooltipOpen(false),
					},
				}}
			>
				{users.length > 0 &&
					users.map((user) => (
						<Fragment key={user.id}>
							{withBadge ? (
								<Badge
									overlap="circular"
									anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
									variant="dot"
									sx={styles.badge}
								>
									{avatarWithTooltip(user)}
								</Badge>
							) : (
								avatarWithTooltip(user)
							)}
						</Fragment>
					))}
			</MuiAvatarGroup>
		</Tooltip>
	);
};
