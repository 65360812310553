import React from 'react';
import { Menu as MuiMenu, MenuItem, Grid, Theme, Typography } from '@mui/material';
import Image from 'next/image';
import { Button } from './Button';
import { UserAvatar } from './UserAvatar';
import { AnchorOriginType, TransformOriginType, UserType } from '@/utils/types';
import Logout from '../assets/icons/Logout.svg';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/slices';

const styles = {
	paper: {
		width: '250px',
		mt: 1,
		borderRadius: '8px',
		p: 1,
		background: (theme: Theme) => theme.palette.action.selected,
		'& ul': {
			p: 0,
		},
	},
	menuItem: {
		fontSize: '13px',
		lineHeight: '15.6px',
		p: '16px',
		height: '48px',
		minWidth: '200px',
		color: (theme: Theme) => theme.palette.text.primary,
		background: (theme: Theme) => theme.palette.common.white,
		borderColor: (theme: Theme) => theme.borderColor.toolbar,
	},
	menuItemProfile: {
		height: '104px',
		borderRadius: '8px 8px 0px 0px',
		borderBottom: (theme: Theme) => `1px solid ${theme.palette.action.selected}`,
		'&:hover, &:focus, &:focus-visible': {
			background: 'white',
		},
	},
	menuItemLogout: {
		height: '49px',
		background: (theme: Theme) => theme.palette.action.selected,
		'&:hover': {
			background: 'none',
		},
	},
	loader: {
		color: (theme: Theme) => theme.palette.primary.main,
	},
};

type MenuPropType = {
	anchorEl: HTMLElement | null;
	anchorOrigin?: AnchorOriginType;
	transformOrigin?: TransformOriginType;
	handleClose: () => void;
	handleLogout: () => void;
	profileHandler: () => void;
};

export const ProfileMenu = ({
	anchorEl,
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	handleClose,
	handleLogout,
	profileHandler,
}: MenuPropType) => {
	const { data: user } = useSelector((state: RootState) => state.me);

	return (
		<MuiMenu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
			PaperProps={{ sx: styles.paper }}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
		>
			<MenuItem sx={{ ...styles.menuItem, ...styles.menuItemProfile }} disableRipple>
				<Grid container direction="column" gap={1}>
					<Grid
						item
						container
						alignItems="center"
						gap={1}
						wrap="nowrap"
						sx={{ cursor: 'default' }}
					>
						<Grid item>
							<UserAvatar
								customStyle={{ height: 24, width: 24, fontSize: 10 }}
								user={user as UserType}
							/>
						</Grid>
						<Grid item>
							<Typography
								fontSize="13px"
								fontWeight={600}
								color="#000"
								lineHeight="15.6px"
							>
								{user?.userName}
							</Typography>
							<Typography fontSize="12px" color="textSecondary" lineHeight="14.4px">
								{user?.email}
							</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Button
							fullWidth
							text={'View Profile'}
							customStyles={{ height: '26px' }}
							onClick={() => {
								profileHandler();
								handleClose();
							}}
						/>
					</Grid>
				</Grid>
			</MenuItem>
			<MenuItem sx={styles.menuItemLogout} onClick={handleLogout}>
				<Grid container alignItems="center" gap={1}>
					<Grid item container alignItems="center" xs={1}>
						<Image src={Logout} alt={'logout'} />
					</Grid>
					<Grid item>
						<Typography fontSize="13px" lineHeight="15.6px">
							Log out
						</Typography>
					</Grid>
				</Grid>
			</MenuItem>
		</MuiMenu>
	);
};
