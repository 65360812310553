import React, { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Grid, IconButton, Theme, Typography } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import parse from 'html-react-parser';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserAvatar } from './UserAvatar';
import { RootState } from '@/store/slices';
import { hasPermission, formatDate } from '@/utils/helpers';
import { CommentType } from '@/utils/types';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.locale('en');

const styles = {
	container: {
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		padding: '1em',
		borderRadius: '8px',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		gap: '8px',
		width: '100%',
	},
	parsedImage: {
		maxWidth: '100%',
		height: 'auto',
	},
	messageContainer: {
		wordBreak: 'break-word',
		'& p': {
			wordBreak: 'break-word',
			lineHeight: '21px',
			color: 'text.primary',
			fontSize: '14px',
			fontFamily: 'Inter',
		},

		'& span.mention': {
			color: (theme: Theme) => theme.palette.primary.main,
			background: 'transparent',
			userSelect: 'none',
		},
	},
};

type CommentCardType = {
	comment: CommentType;
	handleSetAnchorEl: (_event: MouseEvent<HTMLElement>) => void;
};

export const CommentCard: FC<CommentCardType> = ({ comment, handleSetAnchorEl }) => {
	const { data: user } = useSelector((state: RootState) => state.me);

	const parseHtmlContent = (html: string) => {
		return parse(html);
	};

	const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
		handleSetAnchorEl(event);
	};

	return (
		<Grid container direction="column" sx={styles.container}>
			<Grid container gap={1} alignItems="start" direction="column">
				<Grid item width="100%">
					<Grid container gap={1} mb={1} alignItems="center">
						<Grid item>
							<UserAvatar user={comment.user} />
						</Grid>
						<Grid item flexGrow={1}>
							<Typography
								fontSize="13px"
								fontWeight={600}
								lineHeight="16px"
								color="text.primary"
							>
								{comment.user.userName}
							</Typography>
							<Typography fontSize="12px" lineHeight="14px" color="text.secondary">
								{formatDate(comment.createdAt)}
							</Typography>
						</Grid>
						{hasPermission(user, 'general_delete_comments') ||
						user?.id === comment.user.id ? (
							<Grid item textAlign="right">
								<IconButton
									sx={{ cursor: 'pointer', p: 0, m: 0 }}
									onClick={menuClickHandler}
								>
									<MoreVertIcon />
								</IconButton>
							</Grid>
						) : null}
					</Grid>
				</Grid>
				<Grid item sx={styles.messageContainer}>
					{parseHtmlContent(comment.text)}
				</Grid>
			</Grid>
		</Grid>
	);
};
