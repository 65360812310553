import React from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import { Tooltip } from '@mui/material';
import { TASK_FLAG_OPTIONS } from '@/utils/constants';
import { TASK_FLAG } from '@/utils/enums';
import { capitalize } from '@/utils/helpers';
import { TaskType } from '@/utils/types';

type FlagPropsType = {
	task: TaskType;
};

export const Flag = ({ task }: FlagPropsType) => {
	const { flags } = task;
	return flags!.length ? (
		<Tooltip
			title={
				<ul style={{ padding: '4px 15px' }}>
					{flags!.map((f) => {
						const flag = TASK_FLAG_OPTIONS.find((t) => t.id === f);
						return (
							<li key={f} style={{ textTransform: 'none', lineHeight: '22px' }}>
								{capitalize(flag!.name.toLowerCase())}
							</li>
						);
					})}
				</ul>
			}
		>
			<FlagIcon
				sx={{
					fill:
						flags!.includes(TASK_FLAG.NO_ASSIGNED_USERS) ||
						flags!.includes(TASK_FLAG.ASSIGNED_USERS_NOT_INVITED)
							? '#a94943'
							: '#D98D46',
				}}
			/>
		</Tooltip>
	) : null;
};
