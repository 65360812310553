import React, { CSSProperties } from 'react';
import { Avatar, Tooltip } from '@mui/material';
import { getRandomColor, optimizeImg } from '@/utils/helpers';
import { UserType } from '@/utils/types';

type UserAvatarProps = {
	children?: React.ReactNode;
	customStyle?: CSSProperties;
	tooltip?: boolean;
	onClick?: () => void;
	user: UserType;
};

export const UserAvatar: React.FC<UserAvatarProps> = ({
	children,
	customStyle = {},
	tooltip = false,
	onClick,
	user,
}) => {
	const initials = user.userName
		.split(' ')
		.map((part) => part[0])
		.join('')
		.slice(0, 2) // Limit initials to a maximum of 2 characters
		.toUpperCase();

	const imageUrl = user.pictureUrl
		? optimizeImg(user.pictureUrl, (customStyle?.height || 32) as number)
		: '';

	return (
		<Tooltip title={user.userName} disableHoverListener={!tooltip}>
			<Avatar
				onClick={onClick}
				src={imageUrl}
				style={{
					width: 32,
					height: 32,
					fontSize: 14,
					...customStyle,
					...(!imageUrl && {
						backgroundColor: getRandomColor(user.userName),
					}),
				}}
			>
				{children}
				{!imageUrl && initials}
			</Avatar>
		</Tooltip>
	);
};
