import { v4 as uuidv4 } from 'uuid';
import { SURVEY_TYPE, TASK_DUE_OPTION, TASK_FLAG } from './enums';
import { ApplicationRouteType, ChipColorsType } from './types';

export const labelToObj = (name: string): { id: string; name: string } => ({
	id: name.toLowerCase().replace(/[\s]+/g, '_'),
	name,
});

const mapWorkflowOptions = (options: string[]) => {
	return WORKFLOW_OPTIONS.filter((workflow) => options.includes(workflow.id));
};

export const SWIMLANES = {
	[uuidv4()]: {
		id: 'to_do',
		title: 'To Do',
	},
	[uuidv4()]: {
		id: 'in_progress',
		title: 'In Progress',
	},
	[uuidv4()]: {
		id: 'done',
		title: 'Done',
	},
};

export const APP_VERSION = '24';

export const TASK_TABS_LABELS = ['Current', 'Complete', 'Upcoming', 'Archived'];

export const IS_STAGING = process.env.NEXT_PUBLIC_IS_STAGING === 'true';
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '';
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
export const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_KEY || '';
export const BUGSNAG_RELEASE_STAGE = process.env.NODE_ENV || 'development';
export const GUIDES_FOLDER_ID = process.env.NEXT_PUBLIC_GUIDES_FOLDER_ID || '';
export const FILESTACK_KEY = process.env.NEXT_PUBLIC_FILESTACK_KEY || '';
//TODO: Need to hide this key in the CPv2-web server using a proxy.
export const FLATFILE_ENVIRONMENT_ID = process.env.NEXT_PUBLIC_FLATFILE_ENVIRONMENT_ID;
export const FLATFILE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_FLATFILE_PUBLISHABLE_KEY;
export const FLATFILE_SECRET_KEY = process.env.FLATFILE_SECRET_KEY || '';
export const SPLIT_CLIENT_KEY = process.env.NEXT_PUBLIC_SPLIT_CLIENT_KEY || '';
export const HUBSPOT_PORTAL_ID = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID || '';
export const SURVEYJS_LICENSE_KEY = process.env.NEXT_PUBLIC_SURVEY_JS_LICENSE || '';
export const NEXT_PUBLIC_PEXELS_KEY =
	process.env.NEXT_PUBLIC_PEXELS_KEY ||
	'YYDWbpzRO8EbRhM8crPhIM8k8niNdWnw5kY29XQ5XCW1vEHuSZMkOKOY';

export const SKIP_BIGQUERY = process.env.NEXT_PUBLIC_SKIP_BIGQUERY === 'true';
export const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_KEY || '';
export const PUSHER_CLUSTER = process.env.NEXT_PUBLIC_PUSHER_CLUSTER || '';
export const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '';
export const ALGOLIA_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '';
export const ALGOLIA_ARTICLE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_ARTICLE_INDEX || '';
export const ALGOLIA_HELP_DOCS_INDEX =
	process.env.NEXT_PUBLIC_ALGOLIA_HELP_DOCS_INDEX || '';
export const CODOX_API_KEY = process.env.NEXT_PUBLIC_CODOX_API_KEY || '';
export const API_SIGNATURE_SECRET = process.env.API_SIGNATURE_SECRET || '';
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '';

export const CPV1_API_URL = '/api/cpv1';
export const CPV1_DEV_API_URL = '/api/cpv1-dev';
export const CPV2_API_URL = '/api/cpv2';
export const SIGNATURE_URL = '/signature';

export const APP_ROUTES: ApplicationRouteType[] = [
	{
		path: '/',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/dashboards',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/help-docs',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/help-docs/[id]',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/library',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/library/articles/[id]',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/library/dashboards',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/library/dashboards/[id]',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/dashboards/[id]',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/users',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/tasks/[view]',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/tasks/details/[id]',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/surveys',
		visibleTo: ['lp', 'gp', 'portco'],
		permission: 'survey_management',
	},
	{
		path: '/surveys/creator',
		visibleTo: ['lp', 'gp', 'portco'],
		permission: 'survey_management',
	},
	{
		path: '/surveys/[id]',
		visibleTo: ['lp', 'gp', 'portco'],
		permission: 'survey_management',
	},
	{
		path: '/surveys/[id]/results',
		visibleTo: ['lp', 'gp', 'portco'],
		permission: 'survey_management',
	},
	{
		path: '/surveys/[id]/dashboard',
		visibleTo: ['lp', 'gp', 'portco'],
		permission: 'survey_management',
	},
	{
		path: '/files',
		visibleTo: ['lp', 'gp', 'portco'],
	},
	{
		path: '/limited-partners',
		visibleTo: [],
	},
	{
		path: '/general-partners',
		visibleTo: ['lp'],
	},
	{
		path: '/portfolio-companies',
		visibleTo: ['lp', 'gp'],
	},
	{
		path: '/profile',
		visibleTo: ['lp', 'gp', 'portco'],
	},
];

export const ORGANIZATION_STATUS = ['Active', 'Inactive'].map(labelToObj);

export const COMPANY_STATUS = [
	'Active',
	'Due Diligence',
	'Exited',
	'Pending',
	'Inactive',
].map(labelToObj);

export const PHONE_NUMBER_REGEX = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

export const US_PHONE_MASK = [
	'(',
	/[1-9]/,
	/\d/,
	/\d/,
	')',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

export const URL_REGEX = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

export const ENTITY_LEVELS = [
	{ id: 'lp', name: 'Limited Partner' },
	{ id: 'gp', name: 'General Partner' },
	{ id: 'fund', name: 'Fund' },
	{ id: 'portco', name: 'Portfolio Company' },
];

export const USER_LEVELS = [
	{ label: 'Limited Partner', value: 'lp', id: 'lp', name: 'Limited Partner' },
	{ label: 'General Partner', value: 'gp', id: 'gp', name: 'General Partner' },
	{
		label: 'Portfolio Company',
		value: 'portco',
		id: 'portco',
		name: 'Portfolio Company',
	},
];

export const FILE_MAX_SIZE = 1024 * 1024 * 10; // 10 MB

export const PAGINATION_INITIAL_STATE = {
	page: 1,
};

export const TASK_VIEWS = [
	{ id: 'all', name: 'All' },
	{ id: 'assigned-to-me', name: 'Assigned to Me' },
	{ id: 'to-approve', name: 'To Approve' },
	{ id: 'overdue', name: 'Overdue' },
	{ id: 'data-requests', name: 'Data Requests' },
	{ id: 'surveys', name: 'Surveys' },
	{ id: 'company-initiatives', name: 'Company Initiatives' },
	{ id: 'custom-view', name: 'Custom View' },
];

export const LOOKER_TEMPLATE_TYPE_OPTIONS = ['Reference', 'Data', 'Client'].map(
	labelToObj,
);

export const PERMISSION_LEVEL_OPTIONS = [
	'Super Admin',
	'Admin',
	'Technical Manager',
	'Manager',
	'User',
].map(labelToObj);

export const USER_STATUS_OPTIONS = ['Accepted', 'Invited', 'Not Invited', 'Inactive'].map(
	labelToObj,
);

export const TASK_TYPE_OPTIONS = ['Survey', 'Data', 'Deliverable', 'General'].map(
	labelToObj,
);

export const TASK_STATUS_OPTIONS = ['To Do', 'In Progress', 'Done'].map(labelToObj);

export const TASK_DUE_OPTIONS = [
	{ id: TASK_DUE_OPTION.OVERDUE, name: 'Overdue' },
	{ id: TASK_DUE_OPTION.DUE_NEXT_WEEK, name: 'Due Next Week' },
];

export const TASK_FLAG_OPTIONS = [
	{ id: TASK_FLAG.NO_ASSIGNED_USERS, name: 'No Assigned Users' },
	{ id: TASK_FLAG.ASSIGNED_USERS_NOT_INVITED, name: 'Assigned Users Not Invited' },
	{ id: TASK_FLAG.NO_ASSIGNED_APPROVER, name: 'No Assigned Approver' },
];

export const TIME_PERIOD_OPTIONS = ['Annually', 'Quarterly', 'Monthly'].map(labelToObj);

export const YEAR_OPTIONS = ['Year'].map(labelToObj);

export const QUARTER_OPTIONS = ['Q1', 'Q2', 'Q3', 'Q4'].map(labelToObj);

export const MONTH_OPTIONS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
].map(labelToObj);

export const TASK_FILTER_CHIPS = [
	{ id: 'organization', name: 'Entities', isSearch: true, isTreeView: true },
	{ id: 'assignedUser', name: 'Assigned Users', isSearch: true },
	{ id: 'approver', name: 'Assigned Approvers', isSearch: true },
	{ id: 'taskType', name: 'Task Type', isSearch: false },
	{ id: 'workflowStatus', name: 'Workflow', isSearch: false },
	{ id: 'phase', name: 'Phase', isSearch: false },
	{ id: 'due', name: 'Due', isSearch: false },
	{ id: 'flags', name: 'Flags', isSearch: false },
];

export const PREVENT_ENTER_BUTTON_INPUTS = ['summary'];

export const CHIP_COLORS: ChipColorsType = {
	submission_pending: '#425057',
	needs_attention: '#D98D46',
	requires_resubmission: '#D98D46',
	working: '#425057',
	in_review: '#69A6B4',
	awaiting_feedback: '#69A6B4',
	waiting_on: '#A94943',
	high: '#A94943',
	medium: '#D98D46',
	low: '#95A68B',
	to_do: '#A94943',
	in_progress: '#D98D46',
	done: '#95A68B',
};

export const INFINITE_SCROLL_TAKE = 30;

export const DELIVERABLE_TASK_CATEGORIES = [
	'Spreadsheet',
	'Dashboard',
	'Slide Deck',
	'Companion Piece',
	'Other',
].map(labelToObj);

export const WORKFLOW_OPTIONS = [
	{ id: 'submission_pending', name: 'Submission Pending', width: '128px' },
	{ id: 'in_review', name: 'In Review', width: '72px' },
	{ id: 'needs_attention', name: 'Needs Attention', width: '107px' },
	{ id: 'requires_resubmission', name: 'Requires Resubmission', width: '94px' },
	{ id: 'working', name: 'Working', width: '65px' },
	{ id: 'awaiting_feedback', name: 'Awaiting Feedback', width: '76px' },
	{ id: 'waiting_on', name: 'Waiting on', width: '78px' },
	{ id: 'done', name: 'Done', width: '63px' },
];

export const GENERAL_TASK_WORKFLOW_OPTIONS = mapWorkflowOptions([
	'in_review',
	'working',
	'waiting_on',
	'done',
]);

export const DELIVERABLE_TASK_WORKFLOW_OPTIONS = mapWorkflowOptions([
	'in_review',
	'working',
	'awaiting_feedback',
	'waiting_on',
	'done',
]);

export const SURVEY_DATA_WORKFLOW_OPTIONS = mapWorkflowOptions([
	'submission_pending',
	'in_review',
	'needs_attention',
	'requires_resubmission',
	'done',
]);

export const SURVEY_TYPE_OPTIONS = [
	{ id: SURVEY_TYPE.SURVEY, name: 'Internal Survey' },
	{ id: SURVEY_TYPE.EXTERNAL_SURVEY, name: 'External Survey' },
];

export const SURVEY_TABS_LABELS = ['Standard', 'Live', 'Draft', 'Archived'];

export const DEFAULT_FORMAT_OPTIONS_WITH_MENTION = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'code-block',
	'color',
	'background',
	'mention',
];

// https://drive.google.com/drive/folders/1WMA7aqatwjPP35oG5rMhcyoHkVaJ8Pwy
export const TABLECLOTH_PALETTE = [
	// Primary colors
	'#a94943',
	'#425057',
	'#69a6b4',
	'#9fa9ba',
	'#95a68b',
	'#d7dbdd',
	// Secondary colors
	'#f59a4a',
	'#f4c287',
	'#91cba8',
	'#385f68',
	'#586173',
	'#a9c87f',
	'#f0cb6f',
	'#bdbdbd',
	// Tertiary colors
	'#c77053',
	'#dfb897',
	'#87937c',
	'#586f5d',
	'#db572f',
	'#e7ba7e',
	'#f8d775',
	'#9e955c',
	// Bonus colors
	'#353149',
];
