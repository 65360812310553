import React from 'react';
import { useSelector } from 'react-redux';
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	Checkbox,
	TableBody,
	IconButton,
	Theme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { omit } from 'lodash';
import { RootState } from '@/store/slices';
import { isHigherPermissionLevel, isSuperAdmin } from '@/utils/helpers';
import { HeaderCell, Row, SortType, UserType } from '@/utils/types';

type DataTablePropsType = {
	data: any;
	headCells: any;
	selected?: any;
	canEdit?: boolean;
	isSelectable?: boolean;
	isRowClickable?: boolean;
	sort?: SortType;
	setSelected?: (_arg: any) => void;
	handleSort?: (_arg: string) => void;
	setAnchorEl?: (..._arg: any[]) => void;
	handleRowClick?: (index: number) => void;
	users?: UserType[];
};

const styles = {
	tableContainer: {
		background: '#fff',
		overflow: 'auto',
		height: '100%',
		padding: '0px 16px 0px 16px',
		borderRadius: 1,
		'& .MuiTableCell-root': {
			borderColor: (theme: Theme) => theme.borderColor.toolbar,
			fontSize: '14px',
		},
	},
	headerTableCell: {
		py: 0,
		backgroundColor: '#F9F9FB',
		borderBottom: 'none',
		fontWeight: 600,
		paddingBottom: '10px',
		paddingTop: '10px',
	},
	nonSelectableHeaderTableCell: {
		py: 0,
		backgroundColor: '#F9F9FB',
		borderBottom: 'none',
		fontWeight: 600,
		padding: '10px',
		borderRadius: '8px 0px 0px 8px',
	},
	headerDividerCell: {
		padding: '6px 0',
		backgroundColor: 'transparent',
		borderBottom: 0,
	},
	headerCellLabel: {
		whiteSpace: 'nowrap',
	},
	headerCheckbox: {
		paddingLeft: 2,
		color: (theme: Theme) => theme.palette.primary.main,
	},
	checkbox: {
		color: (theme: Theme) => theme.palette.primary.main,
	},
	tableRow: {
		cursor: 'pointer',
	},
};

export const DataTable = ({
	headCells = [],
	data = [],
	sort,
	handleSort = () => {},
	selected = {},
	setSelected = () => {},
	setAnchorEl = () => {},
	//TODO: Disabling all the selectable elements in DataTable as there is no information how to perform bulk op.
	isSelectable = false,
	canEdit,
	handleRowClick = () => {},
	isRowClickable = false,
	users,
}: DataTablePropsType) => {
	const { data: user } = useSelector((state: RootState) => state.me);

	const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const selectedRows = {} as any;
			data.forEach((cellData: Row) => {
				selectedRows[cellData.id] = true;
			});
			setSelected(selectedRows);
		} else setSelected({});
	};

	const handleSelectRow = (event: any, id: string) => {
		event.stopPropagation();
		if (event.target.checked) {
			setSelected({ ...selected, [id]: true });
		} else {
			setSelected(omit(selected, id));
		}
	};

	const canEditRow = (row: Row) =>
		canEdit &&
		// Only allow non-super-admin users to edit users with lower permission levels in users table
		!(
			users &&
			!isSuperAdmin(user!) &&
			!isHigherPermissionLevel(
				user!.permissionLevel,
				users.find((u) => u.id === row.id)!.permissionLevel,
			)
		);

	return (
		<TableContainer sx={styles.tableContainer}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						{isSelectable && (
							<TableCell
								padding="checkbox"
								sx={{ ...styles.headerTableCell, borderRadius: '8px 0 0 8px' }}
							>
								<Checkbox
									color="primary"
									indeterminate={
										Object.keys(selected).length > 0 &&
										data.length > Object.keys(selected).length
									}
									checked={data.length === Object.keys(selected).length}
									onChange={handleSelectAllRows}
									sx={styles.headerCheckbox}
								/>
							</TableCell>
						)}
						{headCells.map((headCell: HeaderCell, index: number) => (
							<TableCell
								key={headCell.id}
								sx={
									!isSelectable && index === 0
										? {
												...styles.nonSelectableHeaderTableCell,
												maxWidth: headCell.maxWidth || 'unset',
										  }
										: !canEdit && index === headCells.length - 1
										  ? { ...styles.headerTableCell, borderRadius: '0 8px 8px 0' }
										  : {
													...styles.headerTableCell,
													maxWidth: headCell.maxWidth || 'unset',
										    }
								}
							>
								<TableSortLabel
									active={sort?.column === headCell.id}
									direction={sort?.column === headCell.id ? sort?.order : 'asc'}
									onClick={() => headCell.sort && handleSort(headCell.id)}
									sx={styles.headerCellLabel}
									hideSortIcon={!headCell.sort}
								>
									{headCell.label}
								</TableSortLabel>
							</TableCell>
						))}
						{canEdit && (
							<TableCell
								sx={{ ...styles.headerTableCell, borderRadius: '0 8px 8px 0' }}
							/>
						)}
					</TableRow>
					<TableRow>
						<TableCell sx={styles.headerDividerCell} />
						{headCells.map((cell: HeaderCell) => (
							<TableCell key={`divider-cell-${cell.id}`} sx={styles.headerDividerCell} />
						))}
						<TableCell sx={styles.headerDividerCell} />
					</TableRow>
				</TableHead>
				<TableBody>
					{data.length > 0 &&
						data.map((row: Row, index: number) => (
							<TableRow
								hover
								role="checkbox"
								key={`table-row-${row.id || index}`}
								sx={{
									...(row.backgroundColor && { backgroundColor: row.backgroundColor }),
									...(isRowClickable && styles.tableRow),
								}}
								selected={!!selected?.[row.id]}
								onClick={() => isRowClickable && handleRowClick(index)}
							>
								{isSelectable && (
									<TableCell padding="none" sx={{ padding: '10px' }}>
										<Checkbox
											sx={styles.checkbox}
											checked={!!selected[row.id]}
											onClick={(event) => handleSelectRow(event, row.id)}
										/>
									</TableCell>
								)}
								{Object.entries(row).map(
									([key, value], index) =>
										!['id', 'backgroundColor'].includes(key) && (
											<TableCell
												key={`table-cell-${index}`}
												padding={
													headCells.find((headCell: HeaderCell) => key === headCell.id)
														?.paddingVariant || 'normal'
												}
												sx={{
													maxWidth:
														headCells.find((headCell: HeaderCell) => key === headCell.id)
															?.maxWidth || 'unset',
												}}
											>
												{value}
											</TableCell>
										),
								)}
								{canEditRow(row) && (
									<TableCell padding="checkbox">
										<IconButton
											onClick={(
												event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
											) => {
												event.stopPropagation();
												setAnchorEl(event.currentTarget, index);
											}}
										>
											<MoreVertIcon />
										</IconButton>
									</TableCell>
								)}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
